<template>
  <div>
    <v-card
      flat
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">NEW TICKETS</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" class="multi-col-validation mt-6">
        <v-card-text>
          <v-col
            md="3"
            cols="12"
          ></v-col>
          <v-row>
            <v-col
              md="12"
              cols="12"
            >
              <v-select
                dense
                outlined
                v-model="induction_details"
                :items="induction_items"
                item-text="id"
                item-value="id"
                label="Inductions"
                required
                @change="selected_induction"
                :rules="rules.combobox_rule"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="year_of"
                label="Year"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="amount"
                label="Amount"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="location"
                label="Locations"
                dense
                outlined
                :rules="rules.default_max_255_character_and_no_empty_rule"
                readonly
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="stabs"
                label="How Many Stabs"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                type="number"
                @keyup="change_stab"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="total_amount"
                label="Total Amount"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              md="12"
              cols="12"
            >

              <v-checkbox
                label="Is Member?"
                hide-details
                class="me-3 mt-1"
                v-model="is_member"
                @change="selected_check"
              >
              </v-checkbox>
              <v-combobox
                v-if="is_member"
                :append-icon="icons.mdiAccountSearchOutline"
                v-model="name_of_sponsor"
                @keyup.enter="searching_search_items($event.target.value)"
                :items="search_items"
                item-value="id"
                item-text="name"
                placeholder="Name of the Member"
                ref="memberCombobox"
                dense
                outlined
              ></v-combobox>
              <v-text-field
                v-else
                v-model="full_name"
                label="Branch/Not Registered Full Name"
                dense
                outlined
                :rules="rules.default_max_255_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <!-- alert -->
      <v-col cols="12" v-show="alert">
        <v-alert
          color="warning"
          text
          class="mb-0"
        >
          <div class="d-flex align-start">
            <v-icon color="warning">
              {{ icons.mdiAlertOutline }}
            </v-icon>

            <div class="ms-3">
              <p class="text-base font-weight-medium mb-1">
                {{alert_message}}
              </p>
            </div>
          </div>
        </v-alert>
      </v-col>

      <v-col cols="12">
        <v-btn
          color="primary"
          class="me-3 mt-4"
          @click="save_member"
          v-if="!saving"
        >
          Save changes
        </v-btn>
        <v-progress-circular
          :size=50
          :width="5"
          color="primary"
          indeterminate
          v-else
        ></v-progress-circular>
      </v-col>
      <v-simple-table dense class="pt-2">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-uppercase">
              Name
            </th>
            <th class="text-center text-uppercase">
              SOA
            </th>
            <th class="text-center text-uppercase">
              Amount
            </th>
            <th class="text-center text-uppercase">
              Balance
            </th>
            <th class="text-center text-uppercase">
              Year Of
            </th>
            <th class="text-uppercase">
              Location
            </th>
            <th class="text-uppercase">
              Delete
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in data"
            :key="item.id"
          >
            <td>
              {{ item.name }}
            </td>
            <td class="text-center">
              <div>
                <v-icon
                  v-if="item.payments.length>0"
                  class="mr-2"
                  color="success"
                  @click="view_soa(item)"
                >
                  {{icons.mdiCash100}}
                </v-icon>
              </div>
            </td>
            <td class="text-center">
              {{ formatPrice(item.total_amount) }}
            </td>
            <td class="text-center">
              {{ formatPrice(get_balance(item)) }}
            </td>
            <td class="text-center">
              {{ item.year_of }}
            </td>
            <td>
              {{ item.location }}
            </td>
            <td class="text-center">
              <div v-if="!saving">
                <v-icon
                  v-if="item.payments.length===0"
                  class="mr-2"
                  color="error"
                  @click="delete_data(item)"
                >
                  {{icons.mdiDelete}}
                </v-icon>
              </div>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <v-dialog v-model="view_dialog" max-width="50%">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">VIEW SOA</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-3">
          <v-simple-table dense class="pt-2">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-center text-uppercase">
                  ID
                </th>
                <th class="text-center text-uppercase">
                  Date
                </th>
                <th class="text-center text-uppercase">
                  Amount
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="item in data_soa"
                :key="item.id"
              >
                <td class="text-center">
                  {{ item.id }}
                </td>
                <td class="text-center">
                  {{ item.deposits.date_of_deposit }}
                </td>
                <td class="text-center">
                  {{ formatPrice(item.amount) }}
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>

    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiAlertOutline,
    mdiCloudUploadOutline,
    mdiAccountPlus,
    mdiCash,
    mdiDelete,
    mdiCash100,
    mdiAccountSearchOutline
  } from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      is_member: true,
      saving: false,
      alert: false,
      alert_message: '',
      full_name: '',
      view_dialog: false,
      data_soa: [],
      name_of_sponsor: '',
      stabs: '',
      search_items: [],
      data: [],

      year_of: '',
      amount: '',
      total_amount: '',
      total_amount_raw: '',
      location: '',
      induction_details: '',
      induction_items: [
        {
          id: '3rd Induction',
          year_of: 2025,
          amount: 40500,
          location: 'SHENZHEN, CHINA'
        },
        {
          id: '2nd Induction',
          year_of: 2024,
          amount: 2500,
          location: 'BORACAY ISLAND, MALAY, AKLAN'
        },
        {
          id: '1st Induction',
          year_of: 2023,
          amount: 500,
          location: 'BIG 8 HOTEL, DIGOS CITY, DAVAO DEL SUR'
        },],
    }
  }

  export default {
    components: {
      snackBarDialog,
    },
    props: {
      value: Object,
      is_edit: Boolean,
    },
    setup() {
      return {
        show: false,

        icons: {
          mdiDelete,
          mdiAccountSearchOutline,
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiAccountPlus,
          mdiCash,
          mdiCash100,
        },
      }
    },
    data() {
      return initialState()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['branch_id', 'district_id', 'district_name', 'user_id', 'month_start', 'month_end']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
    },
    methods: {
      ...mapActions('induction_tickets', ['create_induction_ticket_history', 'list_of_inductions_ticket', 'delete_induction_ticket_history']),
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('members_information', ['search_all_members']),
      selected_check() {
        this.name_of_sponsor = ''
        this.full_name = ''
        this.search_items = []
      },
      change_stab() {
        this.total_amount = this.formatPrice(parseFloat(this.stabs) * parseFloat(this.amount))
        this.total_amount_raw = parseFloat(this.stabs) * parseFloat(this.amount)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      get_balance(item) {
        var payment = 0;
        item.payments.forEach(function (item) {
          payment += parseFloat(item.amount)
        })
        return (parseFloat(item.total_amount) - parseFloat(payment))
      },
      searching_search_items(value) {
        this.search_all_members({
          search_word: value
        })
          .then(response => {
            this.search_items = response.data
          })
      },
      delete_data(item) {
        this.saving = true
        this.delete_induction_ticket_history({
          id: item.id
        })
          .then(response => {
            var color = 'success'
            if (response.status === 201) {
              color = 'error'
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
            this.saving = false
            this.selected_induction()
          })
          .catch(error => {
            this.alert = true
            this.alert_message = error
            console.log(error)
            this.saving = false
          })
      },
      selected_induction() {
        var index = this.induction_items.map(function (x) {
          return x.id;
        }).indexOf(this.induction_details)
        if (index != -1) {
          this.list_of_inductions_ticket({
            details: this.induction_details
          })
            .then(response => {
              this.data = response.data
            })
          this.year_of = this.induction_items[index].year_of + ''
          this.amount = this.induction_items[index].amount + ''
          this.location = this.induction_items[index].location
        }
      },
      async save_member() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          var proceed = true;
          if (this.is_member && this.name_of_sponsor != null) {
            if (this.name_of_sponsor.id === undefined) {
              this.alert = true
              this.alert_message = 'Please Search Referer First'
              this.saving = false
              proceed = false
            }
          }
          if (proceed) {
            const data = new FormData()
            data.append('details', this.induction_details);
            data.append('member_id', this.is_member && this.name_of_sponsor != null ? this.name_of_sponsor.id : '');
            data.append('name', this.is_member && this.name_of_sponsor != null ? this.name_of_sponsor.name : this.full_name.toUpperCase());
            data.append('no_of_stabs', this.stabs);
            data.append('amount', this.amount);
            data.append('total_amount', this.total_amount_raw);
            data.append('year_of', this.year_of);
            data.append('location', this.location.toUpperCase());
            this.create_induction_ticket_history(data)
              .then(response => {
                var color = 'success'
                if (response.status === 201) {
                  color = 'error'
                }
                this.change_snackbar({
                  show: true,
                  color: color,
                  text: response.data,
                })
                this.saving = false
                this.selected_induction()
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                console.log(error)
                this.saving = false
              })
          }
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },

      view_soa(items) {
        this.view_dialog = true
        this.data_soa = items.payments
      },
    }
  }
</script>
